import { useRef }   from 'react';
import NumberFormat from 'react-number-format';
import styled       from 'styled-components';

export const NumberInput = ({ 
  prefix = '',
  thousandSeparator = false,
  format, 
  field: { 
    id, 
    name,
    value
  }, 
  form: { 
    errors, 
    setFieldValue 
  },
  onBlur
}) => {
  const inputRef = useRef(null);

  return (
    <NumberInput.Wrapper>
      <NumberInput.Container
        inputRef          = {inputRef.current}
        format            = {format}
        type              = "text"
        id                = {id}
        autoComplete      = "off"
        prefix            = {prefix}
        thousandSeparator = {thousandSeparator}
        aria-describedby  = "cvvValidation"
        onChange          = {(e) => { setFieldValue(name, e.target.value)}}
        errors            = {errors}
        onBlur            = {onBlur}
        getInputRef       = {(el) => { inputRef.current = el }}
      />

      {name === 'ssn' && <NumberInput.FirstMask onClick={() => { inputRef.current.focus() }}>{Array.from({ length: value.split(' ').join('').length <= 5 ? value.split(' ').join('').length - 2 : 3 }, () => '*').join('')}</NumberInput.FirstMask>}
      {name === 'ssn' && <NumberInput.SecondMask onClick={() => { inputRef.current.focus() }}>{Array.from({ length: value.split(' ').join('').length <= 7 ? value.split(' ').join('').length - 5 : 2 }, () => '*').join('')}</NumberInput.SecondMask>}
      {errors[name] && <NumberInput.Error>{errors[name]}</NumberInput.Error>}
    </NumberInput.Wrapper>
  )
}

NumberInput.Container = styled(NumberFormat)`
  -webkit-appearance    : none;
  -webkit-border-radius : 0;
  box-sizing            : border-box;
  display               : block;
  width                 : 100%;
  height                : 40px;
  padding               : 12px 15px;
  font-size             : 0.75rem;
  letter-spacing        : 0.5px;
  border                : 1px solid #edece8;

  &:focus {
    border-color: #222;
  }

  &:-webkit-autofill {
    filter             : none;
    -webkit-box-shadow : 0 0 0 1000px #fff inset !important;
    box-shadow         : 0 0 0 1000px #fff inset !important;
  }

  @media screen and (min-width: 376px) {
    font-size: 0.875rem;
  }
`;

NumberInput.Error = styled.p`
  margin-top : 6px;
  color      : red;
  font-size  : 12px;
  position   : absolute;
`;

NumberInput.FirstMask = styled.p`
  position         : absolute;
  width            : auto;
  top              : 11px;
  left             : 16px;
  font-size        : 19px;
  background-color : #FFF;
  color            : black;
`;

NumberInput.SecondMask = styled.p`
  position         : absolute;
  width            : auto;
  top              : 11px;
  left             : 45px;
  font-size        : 19px;
  background-color : #FFF;
  color            : black;
`;

NumberInput.Wrapper = styled.div`
  position: relative;
`;