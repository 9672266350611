import {useState, React} from 'react'
import { Field } from 'formik';
import styled from 'styled-components';
import { Layout } from '../components/Layout';
import { Grid } from '../../Grids';
import { Button } from '../../Button';
import { Checkbox } from '../../Checkbox';
import { Label } from '../../Label';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

import SpinnerGif from '../../../images/spinner.gif';

const Spinner = () => <img src={SpinnerGif} alt="" />;

const captchaKey = process.env.REACT_APP_CAPTCHA_CLIENT_KEY;

const validateToken = async(token) => {
  return new Promise((resolve, reject) => {
    axios
        .post(`${process.env.REACT_APP_APIBASEURL}${process.env.REACT_APP_API_VERSION}/captcha/verifytoken?token=${token}`)
        .then((res) => {
            if (res.data.technicalError) {
                reject('api failure');
                console.log('rechap technicalError');
            }
            resolve(res.data.success)
        })
        .catch(() => {
          console.log('rechap reject');
            reject('api failure');
        });
}); 
} 


export const ElectronicConsent = ({ loading }) => {
 
  let _reCaptchaRef
  const [ , setValue] = useState('[empty]');
  const [recaptchaLoaded, setrecaptchaLoaded] = useState(false);
  const [valid_token, setValidToken] = useState(false); 
  const [recpatchaEnabled, ] = useState(process.env.REACT_APP_CAPTCHA_ENABLED == 'true' ? true : false);
  console.log(recpatchaEnabled);
 

  const asyncScriptOnLoad = () => {
    setrecaptchaLoaded(true)
  };

  const handleChange = async(value) => {
    setValue(value)
    console.log("onChange prop - Captcha value:", value);
    if (recpatchaEnabled) {
    if (value === null) {
      setValidToken(false);
      return;
    }
    let tokenResponse = await validateToken(value);
    if(tokenResponse){
      setValidToken(true);
    }else{
      setValidToken(false);
    } 
  }
    
  };

  return (
    <Layout title="Electronic Consent">
    <Grid>

      <p>
        Each person submitting this application/solicitation (“I,” “me” or “my”) agrees and certifies that I:
        (1) read and agree to the disclosures provided,
        (2) supplied true and correct information,
        (3) am applying to Comenity Bank P.O. Box 182273, Columbus, OH 43218-2273 (“Bank”) for a(n) Arhaus Archarge Credit Card Account,
        (4) authorize the Bank to obtain my credit reports,
        (5) understand that my account, if approved, will be governed by the Credit Card Agreement,
        (6) authorize the Bank to share my application information with Arhaus Furniture.
      </p>
      <ElectronicConsent.Title>
        Terms and Conditions
      </ElectronicConsent.Title>

      <iframe title="Terms and Conditions" width="100%" height="500px" src="https://d.comenity.net/Arhaus/common/Legal/disclosures.xhtml" />

      <ElectronicConsent.CheckboxContainer>
        <Field
          id="isApply"
          name="isApply"
          component={Checkbox}
          label={<Label required>By checking this box and selecting ‘Submit Application,’ I agree to the Terms and Conditions, acknowledge receipt of the Privacy Notice, consent to electronically receive documents, and electronically sign this application/solicitation.</Label>}
        />
      </ElectronicConsent.CheckboxContainer>
      <ElectronicConsent.ButtonContainer>
      {recpatchaEnabled  &&<ReCAPTCHA
            style={{ display: "inline-block" }}
            ref={_reCaptchaRef}
            sitekey={captchaKey}
            onChange={handleChange}
            asyncScriptOnLoad={asyncScriptOnLoad}
          />}
        <br /><br />
      </ElectronicConsent.ButtonContainer>

      <ElectronicConsent.ButtonContainer>
        <Button disabled={loading || (recpatchaEnabled && (!recaptchaLoaded || !valid_token)) }>{loading ? <Spinner /> : 'Submit Application'}</Button>
      </ElectronicConsent.ButtonContainer>
    </Grid>
  </Layout>
  )

  };

ElectronicConsent.Title = styled.h3`
  font-size       : 14px;
  line-height     : 1.25;
  font-weight     : bold;
  text-decoration : underline;
  margin-bottom   : 15px;
`;

ElectronicConsent.ButtonContainer = styled.div`
  && {
    display         : flex;
    margin-top      : 17px;
    margin-bottom   : 5px;
    flex-flow       : row nowrap;
    justify-content : center;
  }
`;

ElectronicConsent.CheckboxContainer = styled.div`
  display    : flex;
  margin-top : 15px;
`;